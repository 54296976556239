import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'
// services
import { useIntercom } from 'react-use-intercom'
import HeroFullscreen from '../components/hero-fullscreen'
import { useQuery } from '@apollo/react-hooks'
import { PRODUCT_PLANS } from '../queries/product'
import styles from '../templates/womens-health-product.module.scss'
import ResponsiveImage from '../components/responsive-image'
import { useLocation } from '@reach/router'
import { trackProductViewed } from '../services/tracking'
import Accordion from '../components/accordion'
import WomensAccordion from '../components/womens-accordion'
import RecommendedProducts from '../components/recommended-products'
import { getCurrency } from '../utils/currency'
// components
const Layout = loadable(() => import('../components/layout'))
const SEO = loadable(() => import('../components/seo'))

const IndexPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      directusProductTemplate2(path: { eq: "/product/cbd-gummies-tin" }) {
        page_title
        page_description
        hero_image {
          filename_disk
          title
        }
        hero_image_alt
        hero_description
        hero_title
        section_1_title
        section_1_description
        section_1_icons {
          title
          filename_disk
        }
        section_2_description
        section_3_description
        section_3_quality_items {
          title
          description
        }
        product {
          identifier
          pills {
            pill_image {
              filename_disk
            }
            name
            tagline
            description
            ingredients
            pill_form
            nutrients {
              name
              description
              image {
                filename_disk
                title
              }
              amount
              unit {
                short_name
              }
              nrv
            }
          }
        }
        product_options {
          option_name
          option_price
          filename_disk
          cart_filename_disk
          show_option
        }
      }
      directusProductTemplate2CommonElement {
        whats_inside_title
        why_youll_love_them_title
        faq_title
        section_quality_title
        section_quality_image {
          filename_disk
          title
        }
        nutritional_information_title
        recommended_products_title
      }
    }
  `)

  const {
    page_title,
    page_description,
    hero_image,
    hero_image_alt,
    hero_description,
    hero_title,
    section_1_title,
    section_1_description,
    section_1_icons,
    section_2_description,
    section_3_description,
    section_3_quality_items,
    product,
    product_options,
  } = data.directusProductTemplate2

  const {
    whats_inside_title,
    why_youll_love_them_title,
    faq_title,
    section_quality_title,
    nutritional_information_title,
    recommended_products_title,
  } = data.directusProductTemplate2CommonElement

  const { data: dataPlans, loading: loadingPlans } = useQuery(PRODUCT_PLANS, {
    variables: {
      productId: 'cbd-gummies-tin',
      currencyCacheKey: getCurrency()
    },
  })

  const {
    data: dataPlansBottle,
    loading: loadingPlansBottle,
    error: errorPlansBottle,
  } = useQuery(PRODUCT_PLANS, {
    variables: {
      productId: 'cbd-gummies-bottle',
      currencyCacheKey: getCurrency()
    },
  })

  const [selectedOption, setSelectedOption] = useState<string>('30 gummies')

  const accordionItems = section_3_quality_items.map((item: any) => ({
    question: item.title,
    answer: item.description,
  }))

  const accordionPillItems = product.pills.map((item: any) => ({
    question: (
      <div>
        {item?.pill_image?.filename_disk && (
          <div className={styles.pillImage}>
            <ResponsiveImage
              filename={item.pill_image.filename_disk}
              mobileSize={45}
              desktopSize={280}
              title={item.name}
            />
          </div>
        )}
        <span className={styles.pillTitle}>{item.name}</span>
      </div>
    ),
    answer: (
      <div>
        <p className={styles.tagline}>
          <strong>{item.tagline}</strong>
        </p>
        <p>{item.description}</p>
      </div>
    ),
  }))

  const nutritionalInfoItems = product.pills.map((item: any) => ({
    question: (
      <div>
        {item?.pill_image?.filename_disk && (
          <img
            className={styles.nutritionalPillImage}
            src={`${process.env.GATSBY_MEDIA_URL}${item.pill_image.filename_disk}?w=180`}
            alt={item.name}
          />
        )}
        <h3 className={styles.nutritionalPillTitle}>{item.name}</h3>
      </div>
    ),
    answer: (
      <div className={styles.nutritionWrapper}>
        <p>{item.description}</p>
        <table className={styles.table}>
          <tbody>
            <tr>
              <td>&nbsp;</td>
              <td className={styles.alignRight}>Per gummy</td>
              <td className={styles.alignRight}>% NRV</td>
            </tr>
            {item.nutrients.map((nutrient: any, index: number) => (
              <tr key={index}>
                <td>{nutrient.name}</td>
                <td className={styles.alignRight}>
                  {nutrient.amount > 1000000
                    ? (nutrient.amount / 1000000000).toFixed(1)
                    : nutrient.amount}
                  {nutrient.unit?.short_name || ' billion'}
                </td>
                <td className={styles.alignRight}>
                  {nutrient.nrv ? nutrient.nrv : ' † '}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>† NRV not established</p>
        <p dangerouslySetInnerHTML={{ __html: item.ingredients }} />
      </div>
    ),
  }))

  const location = useLocation()

  const dataPlan = () => {
    if (product_options) {
      if (selectedOption === '30 gummies') {
        return dataPlansBottle ? dataPlansBottle.product_plans : []
      } else {
        return dataPlans ? dataPlans.product_plans : []
      }
    }
    return dataPlans ? dataPlans.product_plans : []
  }

  const heroImage = () => {
    if (product_options) {
      if (selectedOption === '30 gummies') {
        return product_options[0]
      } else {
        return product_options[1]
      }
    }
    return hero_image
  }

  // tracking
  useEffect(() => {
    hero_title.includes('pack')
      ? trackProductViewed({
          name: hero_title,
          url: location.href,
          product_id: product?.identifier,
          category: "women's health",
        })
      : trackProductViewed({
          name: hero_title,
          url: location.href,
          product_id: product?.identifier,
          category: 'tins',
        })
  }, [])

  const { boot } = useIntercom()

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />

      <HeroFullscreen
        title={hero_title}
        heroImageAlt={hero_image_alt}
        image={heroImage()}
        backgroundColor="PrimaryLighter"
        description={hero_description}
        direction="Right"
        loadingPlans={loadingPlans || loadingPlansBottle}
        plans={dataPlan()}
        productOptions={product_options}
        setSelectedOption={setSelectedOption}
      />

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{section_1_title}</h2>
              <hr className={styles.hrLeftAligned} />
              <p dangerouslySetInnerHTML={{ __html: section_1_description }} />
            </div>
            <div className={styles.iconsContainer}>
              <ul className={styles.icons}>
                {section_1_icons.map((icon: any, index: number) => (
                  <li key={index} className={styles.icon}>
                    <div>
                      <img
                        src={`${process.env.GATSBY_MEDIA_URL}${icon.filename_disk}?w=90`}
                        alt={icon.title}
                        width={45}
                        height={45}
                      />
                    </div>
                    <span>{icon.title}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>

      {product.pills.length > 1 ? (
        <section className={styles.sectionPills}>
          <div className={styles.containerPills}>
            <div className={styles.row}>
              <div className={styles.contentCentered}>
                <h2>{whats_inside_title}</h2>
                <hr className={styles.hrDark} />
                <p>{section_2_description}</p>
              </div>
            </div>
            <div className={styles.rowMobile}>
              <div className={styles.contentCentered}>
                <Accordion items={accordionPillItems} isTransparent={true} />
              </div>
            </div>
          </div>

          <div className={styles.desktopPills}>
            {accordionPillItems.map((pill: any, index: number) => (
              <div className={styles.desktopPill} key={index}>
                <div className={styles.desktopPillTitle}>{pill.question}</div>
                <div className={styles.desktopPillDescription}>
                  {pill.answer}
                </div>
              </div>
            ))}
          </div>
        </section>
      ) : (
        <section className={styles.sectionPrimary}>
          <div className={styles.container}>
            <div className={styles.row}>
              <div className={styles.content}>
                <h2>
                  {product.identifier === 'cbd-gummies-tin'
                    ? why_youll_love_them_title
                    : whats_inside_title}
                </h2>
                <hr className={styles.hrDarkLeftAligned} />
                <p
                  dangerouslySetInnerHTML={{ __html: section_2_description }}
                />
              </div>
              <div className={styles.contentTextCentered}>
                {product.pills[0]?.pill_image?.filename_disk && (
                  <img
                    src={`${process.env.GATSBY_MEDIA_URL}${product.pills[0].pill_image.filename_disk}?w=140`}
                    alt="CBD Gummies"
                    width={140}
                    height={105}
                  />
                )}
                <h3>{product.pills[0].name}</h3>
                <hr className={styles.hrDark} />
                <p>
                  <strong>{product.pills[0].tagline}</strong>
                  <br />
                  {product.pills[0].description}
                </p>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className={styles.sectionQuality}>
        <div className={styles.faqContainer}>
          <div className={styles.rowCentered}>
            <div className={styles.image}>
              <img src={'/images/FAQ_Lifestyle_Imagex2.png'} />
            </div>
            <div className={styles.contentQuality}>
              <h2>
                {product.identifier === 'cbd-gummies-tin'
                  ? faq_title
                  : section_quality_title}
              </h2>
              <hr className={styles.hrLeftAligned} />
              <p dangerouslySetInnerHTML={{ __html: section_3_description }} />
              <WomensAccordion items={accordionItems} />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.sectionPrimaryLighter}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.contentNutritionalInfo}>
              <h2>{nutritional_information_title}</h2>
              <hr />

              <WomensAccordion
                isTransparent={true}
                openIfSingleItem={true}
                isLargeRow={true}
                items={nutritionalInfoItems}
              />
            </div>
          </div>
        </div>
      </section>

      <RecommendedProducts title={recommended_products_title} products={[]} />
    </Layout>
  )
}

export default IndexPage
